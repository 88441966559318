<template>
  <div class="finalize">
    <v-card>
      <v-card-text class="text-center">
        <template v-if="!validationErrors.length">
          <v-icon size="64" color="primary">mdi-check-all</v-icon>
          <h2 class="mt-2">{{ $t("all-set") }}</h2>
          <v-btn text color="primary" @click="finalizeAndSave" v-if="!isFinalized()">
            {{ $t("finalize-record") }}
          </v-btn>
        </template>
        <template v-else>
          <v-icon size="64" color="error">mdi-alert</v-icon>
          <h2 class="mt-2">{{ $t("incomplete-datasheet") }}</h2>
        </template>
      </v-card-text>
      <v-card-text>
        <v-alert v-if="!validationErrors.length" type="success" border="top">
          {{ $t("all-necessary-data-has-been-filled-out") }}
        </v-alert>
        <v-alert
          type="error"
          border="top"
          v-for="item in validationErrors"
          :key="item.text"
        >
          <v-row align="center">
            <v-col class="grow py-0">
              {{ item.text }}
              <v-btn text disabled>
                {{ $t(item.page.toLowerCase()) }}
              </v-btn>
            </v-col>
            <v-col class="shrink py-0">
              <v-btn text :to="{ name: item.page }">{{ $t("fix") }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="downloadPdfReport"
        >
          {{ $t("print-datasheet") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="sendPdfReport"
        >
          {{ $t("send-datasheet-via-email") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import RecordMixin from "@/mixins/RecordMixin";
export default {
  name: "Finalize",
  mixins: [RecordMixin],
  computed: {},
  methods: {
    async downloadPdfReport() {
      //TODO: refactor this to the store
      var response = await this.$store.dispatch("pdfReport", this.localRecord.PatientID);
      console.log(response);
      var PatientID = response.PatientID;
      var pdfURL = response.link;
      
      try {
        
        response = await fetch(pdfURL);
        var pdfBlob = await response.blob();
        
        const data = window.URL.createObjectURL(pdfBlob);
        var link = document.createElement('a');
        
        link.href = data;
        link.download = PatientID + "_" + this.$t("report_sheet") + ".pdf";
        link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
        link.click();
      } catch (err)
      {
        window.open(pdfURL);
      }
    },
    async finalizeAndSave() {
      await this.finalize();
      await this.quickSave();
    },
    async sendPdfReport() {
      await this.$store.dispatch("sendEmail", this.localRecord.PatientID);
    }
  }
};
</script>
